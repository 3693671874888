import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="location-selection"
export default class extends Controller {
  static targets = [
    "checkbox",
    "sum",
    "subscriptionOptions",
    "monthlyTotal",
    "yearlyTotal",
    "form",
    "planInput",
  ];

  connect() {
    this.updateSum();
  }

  updateSum() {
    const totalPlaces = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .reduce(
        (sum, checkbox) => sum + parseInt(checkbox.dataset.numberOfPlaces, 10),
        0,
      );

    this.sumTarget.textContent = totalPlaces;
    this.toggleSubscriptionOptions(totalPlaces);
    if (totalPlaces > 0) {
      const totalLocations = this.checkboxTargets.filter(
        (checkbox) => checkbox.checked,
      ).length;

      this.fetchPrices(totalPlaces, totalLocations);
    }
  }

  toggleSubscriptionOptions(totalPlaces) {
    if (totalPlaces > 0) {
      this.subscriptionOptionsTarget.classList.remove("hidden");
    } else {
      this.subscriptionOptionsTarget.classList.add("hidden");
    }
  }

  async fetchPrices(totalPlaces, totalLocations) {
    try {
      const response = await fetch(
        `/prices?units=${totalPlaces}&base_units=${totalLocations}`,
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const prices = await response.json();
      this.updatePrices(prices);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  }

  updatePrices(prices) {
    this.monthlyTotalTarget.textContent =
      prices.monthly.totals.total.toFixed(2);
    this.yearlyTotalTarget.textContent = prices.yearly.totals.total.toFixed(2);
  }

  selectPlan(event) {
    event.preventDefault();
    const plan = event.currentTarget.dataset.plan;
    this.planInputTarget.value = plan;

    const selectedIds = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    if (selectedIds.length === 0) {
      alert("Please select at least one location.");
      return;
    }

    this.formTarget.requestSubmit();
  }
}
