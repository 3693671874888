import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="disclosure"
export default class extends Controller {
  static targets = ["content", "button"];
  connect() {
    this.contentTargets.forEach((content) => (content.hidden = true));
  }

  toggle(event) {
    const button = event.currentTarget;
    const content = button.nextElementSibling;

    content.hidden = !content.hidden;

    const icon = button.querySelector("svg");
    icon.style.transform = content.hidden ? "rotate(0deg)" : "rotate(180deg)";
  }
}
